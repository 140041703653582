import { userTypes } from 'config/config';
import { Route, Routes } from 'react-router-dom';
import AdminDashboard from 'screens/admin/AdminDashboard';
import ChangePassword from 'screens/password/ChangePassword';
import PreferenceStaff from 'screens/staff/PreferenceStaff';

import Layout from '../components/Layout';
import ProtectedRoute from './ProtectedRoute';

const CorporateStaffRoutes = () => (
  <ProtectedRoute allowedRoles={[userTypes.corporateStaff]}>
    <Layout>
      <Routes>
        <Route path="dashboard/*" element={<AdminDashboard />} />{' '}
        <Route path="preference" element={<PreferenceStaff />} />
        <Route path="change-password" element={<ChangePassword />} />
        {/* Assuming a StaffDashboard component */}
        {/* Example staff-specific route */}
      </Routes>
    </Layout>
  </ProtectedRoute>
);
export default CorporateStaffRoutes;
