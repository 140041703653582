import { useState } from 'react';

import { Tab, Tabs } from '@mui/material';
import HeaderComp from 'components/elements/HeadingComponent';

import AddCustomersForInvoice from './components/createInvoiceComp/AddCustomersForInvoice';
import CreateTemplates from './components/createTemplateComp/CreateTemplates';
import InvoicePage from './components/InvoicePage';
import InvoiceList from './update/InvoiceList';

const InvoiceManagement = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  return (
    <div>
      <HeaderComp>Invoice management</HeaderComp>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
      >
        <Tab value={0} label="Create invoice template" />
        <Tab value={1} label="Add customer details" />
        <Tab value={2} label="Create invoice" />
        <Tab value={3} label="Update invoice" />
      </Tabs>
      {activeTab === 0 && <CreateTemplates />}
      {activeTab === 1 && <AddCustomersForInvoice />}
      {activeTab === 2 && <InvoicePage />}
      {activeTab === 3 && <InvoiceList />}
    </div>
  );
};

export default InvoiceManagement;
