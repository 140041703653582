import * as yup from 'yup';

export const validationSchema = yup.object({
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
});

export const validationSchemaRegister = yup.object({
  companyName: yup.string().required('Company name is required'),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
  phone: yup
    .string()
    .matches(/^\d+$/, 'Phone must contain only digits with no spaces')
    .min(5, 'Phone must be at least 5 characters')
    .required('Phone is required'),
});

export const validateGroupName = yup.object({
  groupName: yup
    .string()
    .required('This field is required')
    .matches(
      /^(?! )[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/,
      'Must be alphanumeric with single spaces and cannot start with a space'
    ),
});
export const fileNameValidation = /^(?!.*\.\.).*[\w-]+(\.[\w-]+)$/;
export const validateBankAccount = yup.object().shape({
  accountName: yup
    .string()
    .required('Account Name is required')
    .min(3, 'Account Name must be at least 3 characters')
    .max(50, 'Account Name must not exceed 50 characters')
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      'Account Name must only contain letters, numbers, and spaces'
    ),
  accountNumber: yup
    .number()
    .typeError('Account Number must be a number') // Ensures the input is a number
    .required('Account Number is required') // Required validation
    .min(10000000, 'Account Number must be at least 8 digits') // Adjust the minimum value as needed (for 8 digits)
    .max(999999999999999999, 'Account Number must not exceed 18 digits') // Max value for an 18-digit number
    .integer('Account Number must be an integer'),
  bankName: yup
    .string()
    .required('Bank Name is required')
    .min(3, 'Bank Name must be at least 3 characters')
    .max(50, 'Bank Name must not exceed 50 characters')
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      'Bank Name must only contain letters and spaces'
    ),
  accountType: yup.string().required('Account Type is required'),
  codeType: yup.string().required('Bank Code Type is required'),
  bankCode: yup
    .string()
    .required('Bank Code is required')
    .min(3, 'Bank Code must be at least 3 characters')
    .max(10, 'Bank Code must not exceed 10 characters')
    .matches(
      /^[a-zA-Z0-9]+$/,
      'Bank Code must only contain alphanumeric characters'
    ),
});
export const validateChangeInvoiceStatus = (dueAmount: number) =>
  yup.object({
    closingComments: yup
      .string()
      .trim()
      .required('Closing comments are required')
      .min(10, 'Minimum 10 characters required')
      .max(140, 'Closing comments cannot exceed 140 characters'),

    amount: yup
      .number()
      .typeError('Should be a number')
      .required('Amount is required')
      .positive('Amount must be a positive number')
      .min(1, 'Amount must be at least 1')
      .max(dueAmount, `Amount cannot exceed the due amount of ${dueAmount}`),
  });
