import React, { useEffect, useState } from 'react';

import { AddCircleOutline, DeleteOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { InvoiceProductRow } from '../types';

interface ProductTableProps {
  onTotalsUpdate: (
    subtotal: number,
    totalTax: number,
    totalAmount: number,
    amountAfterDiscount: number
  ) => void;
  discountAmount: number;
  isSaved: boolean;
  rows: InvoiceProductRow[];
  setRows: React.Dispatch<React.SetStateAction<InvoiceProductRow[]>>;
  discountPercentage: number;
  flatDiscount: number;
}

const columnsList = [
  'Product/Service',
  'Description',
  'Qty',
  'Rate',
  'Tax (%)',
  'Tax (Amount)',
  'Amount',
  'Action',
];

const ProductTable: React.FC<ProductTableProps> = ({
  onTotalsUpdate,
  discountAmount,
  isSaved,
  rows,
  setRows,
  discountPercentage,
  flatDiscount,
}) => {
  const [columns, setColumns] = useState(columnsList as string[]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    field: keyof InvoiceProductRow
  ) => {
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: e.target.value,
    };

    // Recalculate amount and tax amount when relevant fields change
    if (field === 'rate' || field === 'quantity' || field === 'taxPercent') {
      const rate = parseFloat(updatedRows[index].rate || '0');
      const quantity = parseInt(updatedRows[index].quantity || '0');
      const taxPercent = parseFloat(updatedRows[index].taxPercent || '0');
      const amount = calculateAmount(rate, quantity);
      const discountPercent = discountPercentage / 100;
      const discountedAmount = (amount - amount * discountPercent).toFixed(2);

      updatedRows[index].discountedAmount = discountedAmount;
      updatedRows[index].taxAmount = (
        (parseFloat(discountedAmount) * taxPercent) /
        100
      ).toFixed(2);
    }

    setRows(updatedRows);
  };

  useEffect(() => {
    const updatedRows = rows.map((row) => {
      const rate = parseFloat((row.rate ?? '0').toString());
      const quantity = parseInt((row.quantity ?? '0').toString());
      const amount = calculateAmount(rate, quantity);
      const discountPercent = discountPercentage / 100;
      const discountedAmount = (amount - amount * discountPercent).toFixed(2);
      const taxAmount = (
        (parseFloat(discountedAmount) * parseFloat(row.taxPercent || '0')) /
        100
      ).toFixed(2);

      return {
        ...row,
        discountedAmount,
        taxAmount,
      };
    });

    setRows(updatedRows);
  }, [discountPercentage]);

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        product: '',
        description: '',
        quantity: '',
        rate: '',
        taxPercent: '',
        taxAmount: '0.00',
        discountedAmount: '0.00',
      },
    ]);
  };

  const handleRemoveRow = (index: number) => {
    const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
  };

  const calculateAmount = (rate: number, quantity: number) => rate * quantity;

  const calculateSubtotal = () =>
    rows.reduce(
      (total, row) => total + parseFloat(row.discountedAmount || '0'),
      0
    );

  const calculateTotalTax = () => {
    return rows.reduce((total, row) => {
      const taxAmount = parseFloat(row.taxAmount || '0.00');
      return total + taxAmount;
    }, 0);
  };

  const calculateTotalAmount = () =>
    calculateSubtotal() + calculateTotalTax() - discountAmount;

  useEffect(() => {
    const subtotal = calculateSubtotal();
    const totalTax = calculateTotalTax();
    const totalAmount = Number(calculateTotalAmount().toFixed(2));
    const amountAfterDiscount = subtotal - flatDiscount;
    onTotalsUpdate(subtotal, totalTax, totalAmount, amountAfterDiscount);
  }, [rows]);

  useEffect(() => {
    if (isSaved && columns.length == 8) {
      columnsList.pop();
      setColumns(columnsList);
    }
  }, []);

  return (
    <>
      <Table sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            {columns.map((column, index) => (
              <TableCell key={index}>
                <Typography variant="body2" fontWeight="bold">
                  {column}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>

              {/* Product/Service */}
              <TableCell>
                {isSaved ? (
                  <Typography>{row.product}</Typography>
                ) : (
                  <TextField
                    value={row.product}
                    onChange={(e) => handleInputChange(e, index, 'product')}
                    size="small"
                    variant="outlined"
                  />
                )}
              </TableCell>

              {/* Description */}
              <TableCell>
                {isSaved ? (
                  <Typography
                    variant="body2"
                    style={{
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                      maxWidth: '100%',
                    }}
                  >
                    {row.description.length > 35
                      ? `${row.description.substring(0, 35)}...`
                      : row.description}
                  </Typography>
                ) : (
                  <TextField
                    value={row.description}
                    onChange={(e) => handleInputChange(e, index, 'description')}
                    size="small"
                    variant="outlined"
                    inputProps={{ maxLength: 35 }}
                  />
                )}
              </TableCell>

              {/* Quantity */}
              <TableCell>
                {isSaved ? (
                  <Typography>{row.quantity}</Typography>
                ) : (
                  <TextField
                    type="number"
                    value={row.quantity}
                    onChange={(e) => handleInputChange(e, index, 'quantity')}
                    size="small"
                    variant="outlined"
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                  />
                )}
              </TableCell>

              {/* Rate */}
              <TableCell>
                {isSaved ? (
                  <Typography>{row.rate}</Typography>
                ) : (
                  <TextField
                    type="number"
                    value={row.rate}
                    onChange={(e) => handleInputChange(e, index, 'rate')}
                    size="small"
                    variant="outlined"
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                  />
                )}
              </TableCell>

              {/* Tax (%) */}
              <TableCell>
                {isSaved ? (
                  <Typography>{row.taxPercent}</Typography>
                ) : (
                  <TextField
                    type="number"
                    value={row.taxPercent}
                    onChange={(e) => handleInputChange(e, index, 'taxPercent')}
                    size="small"
                    variant="outlined"
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                  />
                )}
              </TableCell>

              {/* Tax Amount */}
              <TableCell>
                <Typography>{row.taxAmount || '0.00'}</Typography>
              </TableCell>

              {/* Amount (Discounted Amount) */}
              <TableCell>
                <Typography>{row.discountedAmount}</Typography>
                {/* {isSaved ? (
                  <Typography>{row.discountedAmount}</Typography>
                ) : (
                  <TextField
                    type="number"
                    value={row.discountedAmount}
                    onChange={(e) =>
                      handleInputChange(e, index, 'discountedAmount')
                    }
                    size="small"
                    variant="outlined"
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                  />
                )} */}
              </TableCell>

              {/* Action (Delete Icon) */}
              {!isSaved && (
                <TableCell>
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveRow(index)}
                  >
                    <DeleteOutline />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {!isSaved && (
        <Box p={2} display="flex" gap={4}>
          <Button
            startIcon={<AddCircleOutline />}
            onClick={handleAddRow}
            variant="contained"
          >
            Add product or service
          </Button>
        </Box>
      )}
    </>
  );
};

export default ProductTable;
