import { userTypes } from 'config/config';
import PageNotFound from 'pages/404';
import { Route, Routes } from 'react-router-dom';
import PreferenceCorporate from 'screens/corporate/PreferenceCorporate';
import DashboardPage from 'screens/dashboard/Dashboard';
import ChangePassword from 'screens/password/ChangePassword';
import UserManagement from 'screens/userManagement/UserManagement';

import Layout from '../components/Layout';
import ProtectedRoute from './ProtectedRoute';

const CorporateAdminRoutes = () => (
  <ProtectedRoute allowedRoles={[userTypes.corporateAdmin]}>
    <Layout>
      <Routes>
        <Route path="dashboard/*" element={<DashboardPage />} />
        <Route path="preference" element={<PreferenceCorporate />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="user-management" element={<UserManagement />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Layout>
  </ProtectedRoute>
);
export default CorporateAdminRoutes;
