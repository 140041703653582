import { useEffect, useState } from 'react';

import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { getCustomersForInvoice } from 'screens/invoice/utils';

import { CustomerInterface, savedInvoiceInterface } from '../../types';

interface Props {
  invoiceDetails: savedInvoiceInterface;
  isSaved: boolean;
  setInvoiceDetails: React.Dispatch<
    React.SetStateAction<savedInvoiceInterface>
  >;
}
const CustomerDetailsComp: React.FC<Props> = ({
  invoiceDetails,
  isSaved,
  setInvoiceDetails,
}) => {
  const [customers, setCustomers] = useState([] as CustomerInterface[]);
  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedCustomer = customers.find(
      (customer) => customer.name === event.target.value
    );
    if (selectedCustomer) {
      setInvoiceDetails((prev) => ({
        ...prev,
        customer: selectedCustomer,
      }));
    }
  };
  useEffect(() => {
    getCustomersForInvoice(setCustomers);
  }, []);
  return (
    <>
      {isSaved && (
        <Typography variant="body1" fontWeight="bold" mb={2}>
          Bill to
        </Typography>
      )}
      {isSaved ? (
        <Typography
          variant="body1"
          fontWeight="semibold"
          textTransform="capitalize"
        >
          {invoiceDetails.customer?.name}{' '}
        </Typography>
      ) : (
        <Select
          value={invoiceDetails.customer?.name || ''}
          onChange={handleChange}
          displayEmpty
        >
          <MenuItem value="" key="disabled" disabled>
            Select customer
          </MenuItem>
          {customers.map((cust) => (
            <MenuItem key={cust.name} value={cust.name}>
              {cust.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};

export default CustomerDetailsComp;
