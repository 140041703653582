import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { addressFormInterface } from 'common/interface';
import AddressUpdateComp from 'components/address/AddressUpdateComp';

interface Props {
  handleAddCompany: (data: addressFormInterface) => void;
  companyList: addressFormInterface[];
  handleDeleteCompany: (id: string) => void;
}
type CustomGridValueGetterParams = {
  id: string | number;
  row: Record<string, any>; // Replace `any` with a more specific type if you know your row structure
  field: string;
  value?: any; // Value is often optional
};

const AddCompany: React.FC<Props> = ({
  handleAddCompany,
  companyList,
  handleDeleteCompany,
}) => {
  const capitalizeFirst = (value: string) => {
    return value ? value.replace(/\b\w/g, (char) => char.toUpperCase()) : 'N/A';
  };

  const columns: GridColDef[] = [
    {
      field: 'companyName',
      width: 200,
      renderCell: (params) => (
        <span>
          {' '}
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              alignItems: 'center',

              height: '100%',
            }}
          >
            {capitalizeFirst(params.row.companyName)}
          </Typography>
        </span>
      ),
    },
    {
      field: 'address1',
      headerName: 'Address 1',
      width: 200,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
        >
          {capitalizeFirst(params.row.address1)}
        </Typography>
      ),
    },
    {
      field: 'address2',
      headerName: 'Address 2',
      width: 200,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
        >
          {capitalizeFirst(params.row.address2)}
        </Typography>
      ),
    },
    {
      field: 'city',
      headerName: 'City',
      width: 150,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
        >
          {capitalizeFirst(params.row.city)}{' '}
        </Typography>
      ),
    },
    {
      field: 'state',
      headerName: 'State',
      width: 150,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
        >
          {capitalizeFirst(params.row.state)}
        </Typography>
      ),
    },
    {
      field: 'postCode',
      headerName: 'Postal Code',
      width: 150,
      renderCell: (params) => (
        <Typography
          sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
          variant="body2"
        >
          {capitalizeFirst(params.row.postCode)}
        </Typography>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          color="error"
          onClick={() => console.log(`Delete row with id: ${params.row.id}`)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box mb={5}>
      <AddressUpdateComp
        showCompanyName
        showEditIcon={false}
        handleSubmitForm={handleAddCompany}
        submitButtonLabel="Add company"
      />
      <Box mt={5}>
        <DataGrid
          sx={{ borderRadius: 8, width: '100%' }}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 3,
              },
            },
          }}
          pageSizeOptions={[3]}
          rows={
            companyList.map((company) => ({
              ...company,
              id: company.companyName, // Use companyName as the unique id
            })) as GridRowsProp
          }
        />
      </Box>
    </Box>
  );
};

export default AddCompany;
