import { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Box, Grid, IconButton } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { addressFormInterface } from 'common/interface';
import { universalPutRequest } from 'common/requestHandler';
import { popupNotification } from 'notifications/ToastNotification';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import colors from 'theme/colors';

import GradientButton from '../elements/GradientButton';
import InputElement from '../elements/InputElement';
import { validatePersonalDetails } from './validation';

const AddressUpdateComp: React.FC<{
  onAddressChange?: (data: addressFormInterface) => void;
  handleSubmitForm?: (data: addressFormInterface) => void;
  addressDefaultValues?: addressFormInterface;
  showCompanyName?: boolean;
  showEditIcon?: boolean;
  submitButtonLabel?: string;
  shouldReset?: boolean;
}> = ({
  onAddressChange,
  handleSubmitForm,
  addressDefaultValues = {},
  showCompanyName = false,
  showEditIcon = true,
  submitButtonLabel = 'Update',
  shouldReset = false,
}) => {
  const { username } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<addressFormInterface>({
    resolver: yupResolver(validatePersonalDetails(showCompanyName)),
    mode: 'onChange',
    defaultValues: addressDefaultValues,
  });

  // Reset form when default values change
  useEffect(() => {
    if (addressDefaultValues) {
      reset(addressDefaultValues); // Reset form when default values change
    }
  }, [JSON.stringify(addressDefaultValues), reset]);
  // Submit logic
  const defaultSubmitHandler = useCallback(
    async (data: addressFormInterface) => {
      setLoading(true);
      const addressObject = {
        address1: data.address1,
        address2: data.address2,
        address3: data.address3,
        city: data.city,
        state: data.state,
        postCode: data.postCode,
      };

      const response = await universalPutRequest(
        APIEndpoint.updateUserDetails,
        {
          username,
          address: JSON.stringify(addressObject),
          actionName: 'user',
        }
      );

      if ('error' in response) {
        popupNotification(response.error, false);
      } else {
        popupNotification(response.message, true);
      }
      setLoading(false);
    },
    [username]
  );

  const submitHandler = useCallback(
    (data: addressFormInterface) => {
      if (handleSubmitForm) {
        handleSubmitForm(data);
        reset({
          companyName: '',
          address1: '',
          address2: '',
          address3: '',
          city: '',
          state: '',
          postCode: '',
        });
      } else {
        defaultSubmitHandler(data);
      }
    },
    [handleSubmitForm, defaultSubmitHandler]
  );

  // Input Fields Configuration
  const inputFields = [
    { name: 'address1', label: 'Address 1' },
    { name: 'address2', label: 'Address 2' },
    { name: 'address3', label: 'Address 3' },
    { name: 'city', label: 'City' },
    { name: 'state', label: 'State' },
    { name: 'postCode', label: 'Postal Code' },
  ];

  return (
    <Box>
      <Box
      // sx={{ boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)' }}
      // p={5}
      // pt={2}
      // borderRadius={8}
      >
        {showEditIcon && (
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <IconButton onClick={() => setIsEditable((prev) => !prev)}>
              <ModeEditIcon sx={{ color: colors.editIcon }} />
            </IconButton>
          </Box>
        )}

        <form onSubmit={handleSubmit(submitHandler)}>
          <Grid container spacing={2}>
            {showCompanyName && (
              <Grid item xs={12}>
                <InputElement
                  name="companyName"
                  control={control}
                  label="Company Name"
                  disabled={isEditable}
                />
              </Grid>
            )}

            {inputFields.map((field) => (
              <Grid key={field.name} item xs={12} md={6} sx={{ paddingTop: 0 }}>
                <InputElement
                  name={field.name}
                  control={control}
                  label={field.label}
                  disabled={isEditable}
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              <Box textAlign="right" mt={4}>
                <GradientButton
                  type="submit"
                  disabled={isEditable}
                  rounded
                  loading={loading}
                >
                  {submitButtonLabel}
                </GradientButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default AddressUpdateComp;
