import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { CompanyDataInterface, savedInvoiceInterface } from '../../types';

interface Props {
  invoiceDetails: savedInvoiceInterface;
  setInvoiceDetails: React.Dispatch<
    React.SetStateAction<savedInvoiceInterface>
  >;
  isSaved: boolean;
  editCompanyAddress: boolean;
  setEditCompanyAddress: React.Dispatch<React.SetStateAction<boolean>>;
  companyData: CompanyDataInterface;
}
const CompanyDetailsComp: React.FC<Props> = ({
  invoiceDetails,
  setInvoiceDetails,
  isSaved,
  editCompanyAddress,
  setEditCompanyAddress,
  companyData,
}) => {
  return (
    <>
      <Typography variant="body2">
        {editCompanyAddress && !isSaved ? (
          <Select
            value={invoiceDetails?.address || ''}
            displayEmpty
            onChange={(e: SelectChangeEvent<string>) => {
              const selectedAddress = e.target.value;
              setInvoiceDetails((prev) => ({
                ...prev,
                address: selectedAddress,
              }));
              setEditCompanyAddress(false);
            }}
            fullWidth
          >
            <MenuItem value="" disabled>
              Select company address
            </MenuItem>
            {companyData?.address?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.address}>
                  {item.address}
                </MenuItem>
              );
            })}
          </Select>
        ) : (
          invoiceDetails?.address
            .split(',')
            .map((addressBits: string) => (
              <Typography variant="body2">{addressBits}</Typography>
            ))
        )}
      </Typography>
      {!isSaved && (
        <Button
          variant="text"
          sx={{ p: 0, color: '#3bd6c6' }}
          onClick={() => setEditCompanyAddress(true)}
        >
          Edit company
        </Button>
      )}
    </>
  );
};

export default CompanyDetailsComp;
