import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { addressFormInterface } from 'common/interface';
import { universalGetRequest } from 'common/requestHandler';
import CustomModal from 'components/CustomModal';
import GradientButton from 'components/elements/GradientButton';
import InputElement from 'components/elements/InputElement';
import { popupNotification } from 'notifications/ToastNotification';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { validateGroupName } from 'validation/validationScheme';

// import { AddInvoiceCompanyBankDetails } from './';
// import { formatAddresses, saveTemplate } from '../utils';
import { AddInvoiceCompanyBankDetails } from '../../types';
import { saveTemplate, transformToCompanyAddressInterface } from '../../utils';
import AddCompany from './AddCompany';
import BankDetails from './AddCompanyBankAccounts';
import AddTermsAndCondition from './AddTermsAndCondition';

interface groupNameInterface {
  groupName: string;
}
const CreateTemplates = () => {
  const { companyId } = useSelector((state: RootState) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [templateName, setTemplateName] = useState('test');
  const [companyList, setCompanyList] = useState<addressFormInterface[]>([]);
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [bankList, setBankList] = useState(
    [] as AddInvoiceCompanyBankDetails[]
  );
  const handleAddCompany = (data: addressFormInterface) => {
    setCompanyList((prevCompanyList) => [
      ...prevCompanyList,
      { ...data, address: transformToCompanyAddressInterface(data) },
    ]);
  };
  const { control, handleSubmit, reset } = useForm<groupNameInterface>({
    resolver: yupResolver(validateGroupName),
    mode: 'onChange',
    defaultValues: {
      groupName: '',
    } as groupNameInterface,
  });

  const handleDeleteCompany = (id: string) => {
    setCompanyList((prevCompanyList) =>
      prevCompanyList.filter((company) => company.companyName !== id)
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const handleAddBank = (data: any) => {
    setBankList((prev) => [...prev, data]);
  };
  const handleSaveTemplate = async (data: groupNameInterface) => {
    const payload = {
      httpMethod: 'POST',
      actionName: 'CreateTemplate',
      companyId,
      templateName: data.groupName,
      templateDetails: {
        address: companyList,
        termsAndConditions,
        paymentTerms: ['Immediate', 'Net 30', 'Net 40', 'Net 50'],
        bankAccountDetails: bankList,
      },
    };
    await saveTemplate(payload);
    setShowModal(false);
  };
  const getExistingData = async () => {
    const response: any = await universalGetRequest(
      APIEndpoint.getSecDocsMain,
      {
        companyId,
        httpMethod: 'GET',
        actionName: 'GetInvoiceTemplate',
      }
    );
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      setCompanyList(response.data.address);
      setBankList(response.data.bankAccountDetails);
      setTermsAndConditions(response.data.termsAndConditions);
    }
  };
  useEffect(() => {
    getExistingData();
  }, []);

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2} mt={2}>
        <Accordion sx={{}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography component="span" fontWeight="bold">
              Company details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AddCompany
              handleAddCompany={handleAddCompany}
              companyList={companyList}
              handleDeleteCompany={handleDeleteCompany}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography component="span" fontWeight="bold">
              Account details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BankDetails bankList={bankList} handleAddBank={handleAddBank} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <Typography component="span" fontWeight="bold">
              Terms and conditions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AddTermsAndCondition
              termsAndConditions={termsAndConditions}
              setTermsAndConditions={setTermsAndConditions}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box textAlign="right" mt={5} onClick={() => setShowModal(true)}>
        <GradientButton rounded>Save template</GradientButton>
      </Box>
      <CustomModal
        primaryButtonAction={handleSubmit(handleSaveTemplate)}
        primaryButtonLabel="Save template"
        autoClose={false}
        open={showModal}
        type="info"
        onClose={() => {
          setShowModal(false);
          reset();
        }}
        title="Template name"
      >
        <form onSubmit={handleSubmit(handleSaveTemplate)}>
          <Typography variant="body2" color="textDisabled">
            {' '}
            Type the template name
          </Typography>
          <InputElement
            name="groupName"
            size="small"
            control={control}
            label="Template name"
            autoFocus
            type="text"
            rules={{ required: true }} // Rules can be added here
          />
        </form>
      </CustomModal>
    </>
  );
};

export default CreateTemplates;
