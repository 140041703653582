import React from 'react';

import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import colors from 'theme/colors';

function GradientCircularProgress() {
  return (
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={colors.gradientColor1} />
            <stop offset="100%" stopColor={colors.gradientColor2} />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }}
      />
    </React.Fragment>
  );
}

const OverlayLoader = ({
  loading,
  text = 'Saving',
}: {
  loading: boolean;
  text?: string;
}) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(140, 140, 140, 0.2)', // Semi-transparent background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={loading}
    >
      <Box textAlign="center">
        <CircularProgress size={30} />
        <Typography variant="subtitle1" fontWeight="bold" color="textSecondary">
          {text}
        </Typography>
      </Box>
    </Backdrop>
  );
};

export default OverlayLoader;
