import * as yup from 'yup';

export const validatePersonalDetails = (showCompanyName: boolean) =>
  yup.object({
    companyName: showCompanyName
      ? yup.string().required('Company name is required')
      : yup.string(),
    address1: yup.string().required('Address is required'),
    address2: yup.string(),
    address3: yup.string(),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    postCode: yup
      .string()
      .required('PostCode is required')
      .min(5, 'Minimum length is 5 characters'),
  });
