import { useEffect, useState } from 'react';

import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { Box, MenuItem, Select, Skeleton, Typography } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { universalGetRequest } from 'common/requestHandler';

import { CompanyDataInterface, savedInvoiceInterface } from '../../types';
import { formatKey } from '../../utils';

const orderedKeys = [
  'accountName',
  'accountNumber',
  'accountType',
  'bankName',
  'bankCode',
  'codeType',
];
interface Props {
  invoiceDetails: savedInvoiceInterface;
  setInvoiceDetails: React.Dispatch<
    React.SetStateAction<savedInvoiceInterface>
  >;
  isSaved: boolean;
  companyData: CompanyDataInterface;
  companyId: string | null;
}
const PaymentDetailsComp: React.FC<Props> = ({
  invoiceDetails,
  setInvoiceDetails,
  isSaved,
  companyData,
  companyId,
}) => {
  const [QRImage, setQRImage] = useState<string>('');
  const [loading, setLoading] = useState(true);
  async function getQRImageURL() {
    setLoading(true);
    if (!invoiceDetails.account?.accountNumber) return;
    const response: any = await universalGetRequest(APIEndpoint.getLogo, {
      folderKey: `${companyId}/QR/${invoiceDetails.account.accountNumber}.png`,
    });
    setLoading(false);
    if ('error' in response) {
    } else {
      setQRImage(response.data);
    }
  }
  useEffect(() => {
    if (!invoiceDetails.account) return;
    getQRImageURL();
  }, [invoiceDetails.account]);
  return (
    <>
      <Box
        sx={{
          paddingLeft: 0,
          borderRadius: '10px',
          backgroundColor: '#f9f9f9',
          border: '1px solid #ccc',
          minHeight: '70px',
          padding: '8px',
          overflow: 'auto',
          '&:hover': {
            borderColor: '#3bd6c6',
          },
          '&:focus': {
            borderColor: '#3bd6c6',
            outline: 'none',
            boxShadow: '0 0 4px #3bd6c6',
          },
        }}
        contentEditable={false}
      >
        {!isSaved && (
          <Select
            value={invoiceDetails.account?.accountName || ''}
            displayEmpty
            onChange={(e) => {
              const selectedAccountName = e.target.value;
              const selectedAccount = companyData.bankAccountDetails.find(
                (account) => account.accountName === selectedAccountName
              );
              if (selectedAccount) {
                setInvoiceDetails((prev) => ({
                  ...prev,
                  account: selectedAccount,
                }));
              }
            }}
          >
            {' '}
            <MenuItem value="">
              <em>Select bank account</em>
            </MenuItem>
            {companyData?.bankAccountDetails?.map((account) => (
              <MenuItem key={account.accountNumber} value={account.accountName}>
                {account.accountName}
              </MenuItem>
            ))}
          </Select>
        )}
        <Box mt={2} display="flex" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              {invoiceDetails.account &&
                orderedKeys.map((key) => (
                  <Typography variant="body2" key={key} color="textDisabled">
                    {formatKey(key)}:{' '}
                    {
                      invoiceDetails.account[
                        key as keyof typeof invoiceDetails.account
                      ]
                    }
                  </Typography>
                ))}
            </Box>
          </Box>

          {invoiceDetails.account && (
            <Box display="flex" flexDirection="column">
              {loading ? (
                <div style={{ height: 120, width: 130 }}>
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                </div>
              ) : null}
              {}
              <img loading="lazy" src={QRImage} height={120} />

              {!loading && QRImage.length == 0 ? (
                <ImageNotSupportedIcon fontSize="large" />
              ) : null}
            </Box>
          )}
        </Box>
        {invoiceDetails.account && (
          <Box mt={2} display="flex" justifyContent="space-between">
            <Typography variant="body2" fontWeight="bold">
              Use account details to pay
            </Typography>

            <Typography variant="body2" fontWeight="bold" textAlign="center">
              OR
            </Typography>
            {!loading && QRImage.length ? (
              <Typography variant="body2" fontWeight="bold">
                Scan QR to pay
              </Typography>
            ) : null}
          </Box>
        )}
      </Box>
    </>
  );
};

export default PaymentDetailsComp;
