import React from 'react';

import {
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
  useTheme,
} from '@mui/material';

// Define props for the custom IconButton component
interface CustomIconButtonProps extends IconButtonProps {
  tooltipTitle: string; // Tooltip title to show on hover
  tooltipProps?: Omit<TooltipProps, 'title'>; // Optional props for Tooltip customization (excluding title)
  buttonType: 'primary' | 'error' | 'warning' | 'success'; // Restricting buttonType to specific values
}

const CustomIconButton: React.FC<CustomIconButtonProps> = ({
  tooltipTitle,
  tooltipProps,
  sx,
  buttonType, // Correctly using buttonType
  ...iconButtonProps
}) => {
  const theme = useTheme();
  // Define colors based on buttonType
  const getColor = (
    buttonType: 'primary' | 'error' | 'warning' | 'success'
  ): string => {
    switch (buttonType) {
      case 'error':
        return theme.palette.error.light;
      case 'warning':
        return theme.palette.warning.light;
      case 'primary':
        return theme.palette.primary.light;
      case 'success':
        return theme.palette.success.light;

      default:
        return theme.palette.primary.light;
    }
  };

  return (
    <Tooltip
      title={tooltipTitle}
      arrow
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: getColor(buttonType),
            '& .MuiTooltip-arrow': {
              color: getColor(buttonType),
            },
            fontWeight: 600,
          },
        },
      }}
      {...tooltipProps} // Spread additional tooltipProps
    >
      {/* Remove the color prop and use sx for custom color */}
      <span>
        <IconButton
          {...iconButtonProps}
          sx={{ ...sx, color: getColor(buttonType) }}
        >
          {iconButtonProps.children}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default CustomIconButton;
