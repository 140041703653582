import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';

interface ControlledDatePickerProps {
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
}

const SmallDateComp: React.FC<ControlledDatePickerProps> = ({
  value,
  onChange,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-US">
      <DemoContainer
        sx={{ padding: 0, marginBottom: 0 }}
        components={['DatePicker', 'DatePicker', 'DatePicker']}
      >
        <DatePicker
          views={['year', 'month', 'day']}
          format="MM/DD/YYYY"
          value={value}
          onChange={(newValue) => onChange(newValue)}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default SmallDateComp;
