import { TextField } from '@mui/material';

type Props = {
  termsAndConditions: string;
  setTermsAndConditions: React.Dispatch<React.SetStateAction<string>>;
};
const AddTermsAndCondition: React.FC<Props> = ({
  termsAndConditions,
  setTermsAndConditions,
}) => {
  return (
    <div>
      <TextField
        multiline
        rows={3}
        variant="outlined"
        value={termsAndConditions}
        onChange={(e) => {
          setTermsAndConditions(e.target.value);
        }}
        placeholder="Tell your customer how you want to get paid"
        fullWidth
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            backgroundColor: '#f9f9f9',
            '&:hover fieldset': {
              borderColor: '#3bd6c6',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#3bd6c6',
              borderWidth: '2px',
            },
          },
          '& .MuiInputLabel-root': {
            color: '#666',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3bd6c6',
          },
        }}
      />
    </div>
  );
};

export default AddTermsAndCondition;
