import { Box, Link, Typography } from '@mui/material';
import logoTasalli from 'assets/images/logo-img.png';
import logo from 'assets/images/logo.png';

const Footer = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
      <Typography variant="body2" fontWeight="bold">
        Powered by
      </Typography>
      <img src={logo} alt="Logo" height={25} />
      <Typography variant="body2" fontWeight="bold">
        Developed by
      </Typography>
      <img src={logoTasalli} alt="Logo" height={25} />
      <Link
        href="https://tasalli.com/securedocs/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography variant="body1" className="gradient-text">
          tasalli.com
        </Typography>
      </Link>
    </Box>
  );
};

export default Footer;
